<script setup>
const props = defineProps({
  heading: String,
  body: String,
  cta: Object,
  media: Object,
});
const standardHero = ref(null);
const currentTheme = ref(null);
const isDarkTheme = ref(null);

onMounted(() => {
  const { getCurrentTheme, isBlackText } = useTheme();
  currentTheme.value = getCurrentTheme();
  isDarkTheme.value = isBlackText(currentTheme.value);
});

watch(currentTheme, () => {
  if (!process.client) return;
  if (!standardHero.value) return;
  if (isDarkTheme.value) standardHero.value.dataset.sectionTheme = "dark";
});
</script>

<template>
  <section>
    <div
      class="hero-standard"
      ref="standardHero"
      :class="`bg-${currentTheme} text-${currentTheme}-text transition-colors duration-300`"
    >
      <ui-bleed
        class="grid items-end pb-6 pt-40 md:grid-cols-2 md:gap-[var(--grid-gap)] md:pt-52"
      >
        <div class="md:grid md:grid-cols-6">
          <!-- <h1 class="pb-10 text-xxl md:col-span-5 md:pb-0">
            <span v-html="heading"></span>
          </h1> -->
          <component-element-highlighted-text
            class="md:col-span-5"
            :class="{ 'pb-10 md:pb-0': body }"
            :highlighted-text="heading"
            :has-color="false"
            type="xxl"
            :has-scramble-effect="true"
          />
        </div>
        <div class="md:grid md:grid-cols-6" v-if="body">
          <ui-font-text class="md:col-span-5" type="s">
            <span v-html="body"></span>
            <div class="mt-6" v-if="cta">
              <ui-call-to-action
                :cta="cta"
                :has-background="true"
                :invert-background="true"
              />
            </div>
          </ui-font-text>
        </div>
      </ui-bleed>
    </div>
    <ui-bleed class="py-bleed" v-if="media">
      <div class="aspect-square md:aspect-video">
        <component-common-media
          class="h-full w-full object-cover"
          :media="media"
          loading="eager"
        ></component-common-media>
      </div>
    </ui-bleed>
  </section>
</template>
